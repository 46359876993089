import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Photography from '../views/Photography.vue'
import Videos from '../views/Videos.vue'
import Music from '../views/Audio.vue'
import DJ from '../views/Dj.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About
  },
  {
    path: '/jpeg',
    name: 'Photography',
    component: Photography
  },
  {
    path: '/mp4',
    name: 'Videos',
    component: Videos
  },
  {
    path: '/mp3',
    name: 'MP3',
    component: Music
  },
  {
    path: '/dj',
    name: 'DJ',
    component: DJ
  }
]

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
