<template>
  <div class="container">
    <MobileNav v-model="visible" @toggleDark="handleToggleDark"  />
    <div class="header">
      <!-- <img v-if="windowHeight < 600 && currentRoute !== '/'" @click="visible = !visible" src="../assets/eoin_logo.png" class="logo"/> -->
      <i v-if="windowHeight < 600 && currentRoute !== '/'" @click="visible = !visible" class="fa-solid fa-bars hamburger-icon"></i>
      <img v-if="windowHeight < 600" src="../assets/eoin_logo.png" class="logo"/>
      <h1 class="header-text" @click="handleHeadingClick">Owen Tighe Mahoney</h1>
    </div>
    <div v-if="windowHeight >= 600" class="nav">
      <Nav @toggleDark="handleToggleDark"/>
    </div>
    <div class="content">
      <router-view/>
    </div>
    <!-- <router-view/> -->
  </div>
</template>

<script>
import Nav from "../components/Nav.vue"
import MobileNav from "../components/MobileNav.vue"

export default {
  components: {
    Nav,
    MobileNav
  },
  data() {
    return {
      windowHeight: window.innerWidth,
      visible: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  beforeUnmount() { 
    window.removeEventListener('resize', this.onResize); 
  },
  methods: {
    handleToggleDark(active) {
      console.log(active)
      this.$emit('toggleDark', active)
    },
    onResize() {
      this.windowHeight = window.innerWidth;
    },
    toggleNav(showNav) {
      console.log('toggleNav')
      this.visible = showNav
    },
    handleHeadingClick() {
      this.$router.push('/')
    }
  },
  computed: {
    currentRoute() {
      console.log(this.$router.currentRoute)
      console.log(this.$router.currentRoute.path)
      return this.$router ? this.$router.currentRoute.path : ""
    }
  }
}
</script>

<style scoped>
.container {
  display: grid;
  grid-template-rows: 100px 1fr;
  grid-template-columns: 150px 1fr;
  /* height: 100%; */
  /* width: 100vh;
  width: 1200px; */
}
.header {
  grid-row: 1 / span 1;
  grid-column: 2 / span 1;
  /* border-bottom: 1px solid black; */
}
.nav {
  grid-row: 1 / span 2;
  grid-column: 1 / span 1;
  /* border-right: 1px solid purple; */
}
.content {
  grid-row: 2 / span 1;
  grid-column: 2 / span 1;
  /* border: 1px solid green; */
}
@media screen and (max-width: 600px) {
  .container {
    display: grid;
    grid-template-rows: 30px 1fr;
    grid-template-columns: 0fr 1fr;
  }
  .header {
    grid-row: 1 / span 1;
    grid-column: 2 / span 1;
    display: grid;
    grid-template-columns: min-content min-content 1fr;
  }
  .header-text {
    font-size: 20px !important;
    text-align: center !important;
    text-wrap: nowrap;
    margin-right: 0px !important;
    grid-column: 3 !important;
  }
  .logo {
    grid-column: 2 !important;
    width: 25px !important;
    height: 25px !important;
    margin-left: 10px;
    margin-top: 15px;
  }
}
.header-text {
  font-size: 50px;
  text-align: center;
  grid-column: 2;
  margin-right: 150px;
  cursor: pointer;
  font-weight: 1000;
}
.logo {
  width: 100px;
  height: 100px;
  cursor: pointer;
  grid-column: 1;
}
.hamburger-icon {
  margin-left: 20px;
  margin-top: 20px;
  grid-column: 1;

}
</style>