<template>
  <div>
    <h1 class="header">.mp4</h1>
    <!-- <video controls src="https://cdn.sanity.io/files/1bk1c2xl/production/c753b64394030108ef096904d3c83f463efba410.mp4" /> -->
    <div class="flex-container">
      <div v-for="(video, index) in allVideos" :key="index" class="item">
        <p class="title">{{video.title}}</p>
        <!-- <p>{{video.description}}</p> -->
        <video v-if="video.source === 'file'" autoplay muted loop controls controlslist class="video">
          <source :src="video.videoUrl" class="video">
        </video>
        <iframe 
          v-else-if="video.source === 'youtube'" 

          :src="video.link + '?&loop=1&autoplay=1&mute=1'" 
          title="YouTube video player" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowfullscreen>
        </iframe>
        <iframe 
          v-else-if="video.source === 'vimeo'"
          :src="video.link" 

          frameborder="0" 
          allow="autoplay; fullscreen; picture-in-picture" 
          allowfullscreen>
        </iframe>
      </div>
      <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/RDbV5smdo5I?&autoplay=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
<!-- <p><a href="https://vimeo.com/250196726">Tune-Yards | Heart Attack | Official Music Video</a> from <a href="https://vimeo.com/littlecave">mimi cave</a> on <a href="https://vimeo.com">Vimeo</a>.</p> -->
    </div>
  </div>
</template>

<script>
import sanity from "../client";

export default {
 data() {
    return {
      videosResponse: null
    }
  },
  async mounted() {
    // console.log(AxiosInstance)
    // console.log(this.$axios)
    // console.log(this.videosResponse)
    // let response = await this.$axios({
    //   method: 'get',
    //   url: 'api/videos?populate=%2A'
    // })
    
    const query = `*[_type == 'video' && !(_id in path("drafts.**"))]{"videoUrl": file.asset->url, ...}|order(orderRank)`
    let response = await sanity.fetch(query)
    console.log(response)
    this.videosResponse = response

    // this.$axios('api/photos?populate=%2A')
  },
  computed: {
    allVideos() {
      return this.videosResponse ? this.videosResponse : []
    }
  }
}

</script>

<style scoped>
.header {
  margin-left: 15px;
}
.title {
  margin: 0px;
}
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */
}
.item {
  margin: 5px 10px;
}
.video, iframe {
  width: 400px;
  height: 400px;
  object-fit: cover;
}
@media screen and (max-width: 600px) {
  .video, iframe {
    width: 90vw;
    /* height: 400px; */
    object-fit: cover;
  }

}
</style>