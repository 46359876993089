<template>
  <div class="about">
    <h1>.about</h1>
    <p class="body-text">{{ aboutText.about }}</p>
    <ul class="social-list">
      <li>
        <i class="fa-regular fa-envelope social-icon"></i>
        <a href="mailto:oetm20@gmail.com" target="_blank">oetm20@gmail.com</a>
      </li>
      <li>
        <i class="fa-solid fa-dog social-icon"></i>
        <a href="https://dalmatianofficial.com/" target="_blank">Dalmatian</a>
      </li>
      <li>
        <i class="fa-brands fa-instagram social-icon"></i>
        <a href="https://www.instagram.com/eoin.tm/" target="_blank">Instagram</a>
      </li>
      <li>
        <i class="fa-brands fa-youtube social-icon"></i>
        <a href="https://www.youtube.com/@eoin.mp4" target="_blank">Youtube</a>
      </li>
      <li>
        <i class="fa-brands fa-tumblr social-icon"></i>
        <a href="https://www.tumblr.com/blog/tigheshawty" target="_blank">Tumblr</a>
      </li>
    </ul>
  </div>
</template>

<script>
import sanity from "../client";

export default {
  data() {
    return {
      aboutResponse: null,
    }
  },
  async mounted() {
    const query = `*[_type == 'about' && !(_id in path("drafts.**"))]{"imageUrls": images[].asset->url, ...}|order(orderRank)`
    let response = await sanity.fetch(query)
    this.aboutResponse = response
  },
  methods: {},
  computed: {
    aboutText() {
      return this.aboutResponse && this.aboutResponse.length ? this.aboutResponse[0] : {about: ""}
    }
  }
}
</script>


<style scoped>
.about {
  padding-left: 15px;
  padding-right: 15px;
}
.body-text {
  max-width: 1000px;
}
.social-list {
  padding: 0; 
}
.social-list li {
  list-style-type: none;
  padding: 0; 
  margin: 0;
}
.social-icon {
  margin-right: 10px;
  color: black;
}
</style>
