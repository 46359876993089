import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from './plugins/axios'
import PrimeVue from 'primevue/config';
//theme
import "primevue/resources/themes/lara-light-indigo/theme.css";     
    
//core
import "primevue/resources/primevue.min.css";

const app = createApp(App)

app.use(router)
app.use(axios, {
  // baseUrl: 'http://localhost:1337/',
  baseUrl: 'https://eoin-portfolio-strapi.onrender.com/'
})
app.use(PrimeVue);
app.mount('#app')
