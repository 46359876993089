<template>
  <div>
    <h1 class="header">.jpeg</h1>
    <div class="flex-container">
      <div v-for="(photo, index) in allPhotos" :key="index" @click="expandItem(photo)" class="item grow">
        <div class="item-header">
          <p class="title">{{photo.title}}</p>
        </div>
          <img :src="photo.imageUrls[0]" class="image"/>
      </div>
    </div>
    <div v-if="openItem" class="dialog" @click.self="closeDialog">
      <div @click="closeDialog" class="close-button">
        <span class="material-icons close-icon">close</span>
      </div>
      <div class="image-dialog-container">
        <img :src="openItem.imageUrls[carouselIndex]" class="large-image" />
        <p class="photo-description">{{ openItem.title }}</p>
        <p class="photo-description">{{ openItem.title}} <br> {{openItem.description}}</p>
      </div>
      <div v-if="openItem.imageUrls.length > 1" @click="onCarouselBtn(false)" class="carousel-btn">
        <span class="material-icons arrow-icon">chevron_left</span>
      </div>
      <div v-if="openItem.imageUrls.length > 1" @click="onCarouselBtn(true)" class="carousel-btn right-btn">
        <span class="material-icons arrow-icon">chevron_right</span>
      </div>
    </div>
  </div>
</template>

<script>
// import {AxiosInstance} from 'axios'
// import { useSanityFetcher } from 'vue-sanity'
import sanity from "../client";

export default {
  data() {
    return {
      photosResponse: null,
      openItem: null,
      carouselIndex: 0
    }
  },
  async mounted() {
    const query = `*[_type == 'photo' && !(_id in path("drafts.**"))]{"imageUrls": images[].asset->url, ...}|order(orderRank)`
    let response = await sanity.fetch(query)
    // console.log(response)
    this.photosResponse = response

    // https://1bk1c2xl.api.sanity.io/v2021-10-21/data/query/production?query=*%5B_type%20%3D%3D%20%22person%22%20%26%26%20!(_id%20in%20path(%22drafts.**%22))%5D
    // *[_type == "person" && !(_id in path("drafts.**"))]

    // https://1bk1c2xl.api.sanity.io/v2021-10-21/data/query/production?query=*%5B_type%20%3D%3D%20'person'%5D%7B%0A%20%20%22imageUrl%22%3A%20portrait.asset-%3Eurl%2C%0A%20%20...%0A%7D
    // *[_type == 'person']{
    //   "imageUrl": portrait.asset->url,
    //    ...
    // }
  },
  methods: {
    expandItem(photo) {
      this.openItem = photo
    },
    onCarouselBtn(forward) {
      if(forward) {
        this.carouselIndex = (this.carouselIndex + 1) % this.openItem.imageUrls.length
      } else {
        this.carouselIndex = (this.openItem.imageUrls.length + (this.carouselIndex - 1)) % this.openItem.imageUrls.length
      }
      
    },
    closeDialog() {
      this.openItem = null
      this.carouselIndex = 0
    }
  },
  computed: {
    allPhotos() {
      return this.photosResponse ? this.photosResponse : []
    }
  }
}
</script>

<style scoped>
.header {
  margin-left: 15px;
}
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */
}
.item {
  margin: 5px 10px;
  cursor: pointer;
}
.image {
  height: 400px;
  width: 400px;
  object-fit: cover;
  object-position: 25% 25%; 
}
@media screen and (max-width: 600px) {
  .image {
    height: 300px;
    width: 300px;
  }
  .large-image {
    width: 90vw;
  }
}

.grow { 
transition: all .2s ease-in-out; 
}

.grow:hover { 
transform: scale(1.05); 
}
.item-header {
  display: grid;
  grid-template-columns: 50% 50%;
}
.title {
  grid-column: 1;
  margin: 0px;
}
.arrow-icon {
  color: white;
  font-size: 30px;
  margin-top: 10px;
  margin-left: 10px;
}
.image-dialog-container {
  position: absolute;
  top: 0; left: 0; bottom: 0; right: 0;
  margin: auto;
  width: fit-content;
  height: fit-content;
  justify-content: center;
}
.dialog {
  position: fixed; /* Stay in place */
  z-index: 0; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.8); /* Black w/ opacity */
  backdrop-filter: blur(3px);
}
.dialog-content {
  width: 80vw;
  top: 10%;
  background-color: white;
  margin-left: auto;
  margin-right: auto;
}
.large-image {
  max-height: 80vh;
}
.photo-description {
  color: white;
  position: absolute;
}
.carousel-btn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  top: calc(50% - 25px);
  z-index: 10;
  opacity: 0.3;
  cursor: pointer;
  margin-left: 10px;

}
.right-btn {
  right: 10px
}
.close-button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 10;
  opacity: 0.3;
  cursor: pointer;
}
.close-icon {
  font-size: 20px;
  color: white;
  margin-top: 5px;
  margin-left: 5px;
}
</style>