<template>
  <div class="home">
    <h1>My Latest Work:</h1>
    <h1>TIGHETAPES: A Year In Full</h1>
    <iframe 
      src="https://www.youtube.com/embed/i69D-a-pgAI?si=Ixb43sX8tm-7Ryul"
      title="YouTube video player" 
      frameborder="0" 
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
      allowfullscreen>
    </iframe>
  </div>
</template>

<script>

export default {
  name: 'Home'
}
</script>

<style scoped>
.home-image {
  display: block;
  width: 100vh;
  max-height: 100vh;
  margin: auto;
}
.home {
  text-align: center;
  margin-right: 150px;
}
iframe {
  width: 700px;
  height: 500px;
  object-fit: cover;
}
@media screen and (max-width: 600px) {
  iframe {
    width: 90vw;
    object-fit: cover;
  }
  .home {
    margin-right: 0px;
  }

}
</style>
