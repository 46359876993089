import axios from 'axios'
// import {App} from 'vue'

// class AxiosOptions {
//   constructor(baseURL, token) {
//     this.baseUrl = baseURL
//     this.token = token
//   }
// }

export default {
    install: (app, options) => {
        app.config.globalProperties.$axios = axios.create({
            baseURL: options.baseUrl,
            headers: {
                Authorization: options.token ? `Bearer ${options.token}` : '',
            }
        })
    }
}