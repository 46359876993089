<template>
  <div>
    <h1 class="header">.mp3</h1>
    <div class="flex-container">
      <div v-for="(audio, index) in allMusic" :key="index" class="item">
        <p class="title" :class="{'mp3-title': audio.source === 'mp3'}">{{audio.title}}</p>
        <!-- <p>{{audio.description}}</p> -->
        <div v-if="audio.source === 'mp3'" class="mp3">
          <img :src="audio.coverArtUrl" class="cover-image"/>
          <audio loop controls controlslist class="mp3-controls">
            <source :src="audio.audioUrl">
          </audio>
        </div>
        <iframe
          v-else-if="audio.source === 'soundcloud'" 
          scrolling="no" 
          frameborder="no" 
          allow="autoplay" 
          :src="audio.link + '&color=%237a1ce6&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true'">
        </iframe>
        <iframe 
          v-else-if="audio.source === 'spotify'"
          style="border-radius:12px" 
          :src="audio.link" 
          frameBorder="0" 
          allowfullscreen="" 
          allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
          loading="lazy"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import sanity from "../client";

export default {
 data() {
    return {
      audioResponse: null
    }
  },
  async mounted() {
    // console.log(AxiosInstance)
    const query = `*[_type == 'music' && !(_id in path("drafts.**"))]{"audioUrl": audioFile.asset->url, "coverArtUrl": audioCoverArt.asset->url, ...}|order(orderRank)`
    let response = await sanity.fetch(query)
    console.log(response)
    this.audioResponse = response

    // this.$axios('api/photos?populate=%2A')
  },
  computed: {
    allMusic() {
      return this.audioResponse ? this.audioResponse : []
    }
  }
}

</script>

<style scoped>
.header {
  margin-left: 15px;
}
.flex-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* align-items: center; */
}
.item {
  margin: 0px 10px;
}
.title {
  margin: 0px;
}
.audio, iframe {
  /* width: 500px;
  height: 40px; */
  /* display: block; */
  width: 400px;
  height: 400px;
  object-fit: cover;
}
@media screen and (max-width: 600px) {
  .audio, iframe {
    width: 90vw;
  }
  .mp3 {
    /* width: 350px !important; */
    width: 90vw !important;
  }
  .cover-image {
    width: 90vw !important;
  }
}
.cover-image {
  width: 400px;
  height: 400px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  object-fit: cover;
}
.mp3{
  position: relative;
  width: 400px;
  height: 400px;
}
.mp3-controls {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  margin-bottom: 5px;
}
</style>